import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { Modal, ModalBody, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { Image } from '@msdyn365-commerce/core';
import * as React from 'react';
import { IPopupProps } from './Popup.props';

export const Popup = (popupProps: IPopupProps) => {
    const {
        dialogStrings,
        className,
        imageSettings,
        gridSettings,
        data: { product },
        productQuantity,
        modalOpen,
        setModalOpen,
        context
    } = popupProps;

    if (!dialogStrings || !product) {
        return null;
    }
    const closeModal = () => {
        setModalOpen(false);
    };
    const fallbackImageUrl = getFallbackImageUrl(product.ItemId, context.actionContext.requestContext.apiSettings);
    const quantity = productQuantity !== undefined ? productQuantity : 1;
    const itemsString =
        quantity === 1 ? dialogStrings.headerItemOneText : dialogStrings.headerItemFormatText.replace('{0}', quantity.toString());

    // console.log(product.AttributeValues?.slice());
    const tags = ['20Glutenfree', '18Vegan', '22Noaddedsugar'];
    const tagFriendlyNames = ['Gluten free', 'Vegan friendly', 'Light'];
    const specificationsList = ['25Productdescription(webs', '17Nutritionalvalues', '15Ingredients'];
    const specificationsListFiendly = ['Description', 'Nutritional values', 'Ingredients'];
    return (
        <Modal isOpen={modalOpen} onClosed={closeModal} className={`${className}__dialog`}>
            <ModalHeader toggle={closeModal} className={`${className}__dialog__header`}>
                <span>{itemsString}</span>
                <span>{dialogStrings.headerMessageText}</span>
            </ModalHeader>
            <div className={'container'}>
                <ModalBody className={`${className}__dialog__body row`}>
                    <div className={'col-md-5'}>
                        <Image
                            className={`${className}__dialog__image`}
                            src={product.PrimaryImageUrl || ''}
                            fallBackSrc={fallbackImageUrl}
                            altText={product.Name}
                            gridSettings={gridSettings || {}}
                            imageSettings={imageSettings}
                            loadFailureBehavior='empty'
                        />
                    </div>
                    <div className={`${className}__dialog__product-info__ctn col-md-7`}>
                        <h1 className={`${className}__dialog__product-title`}>{product.Name}</h1>
                        <div className={`${className}__dialog__product-price`}>
                            {context.cultureFormatter.formatCurrency(product.Price)}{' '}
                        </div>
                        <div className={'product-tags'}>
                            {// @ts-ignore
                            product.AttributeValues?.slice()?.map((item, i) => {
                                if (item.KeyName && tags.includes(item.KeyName) && item.TextValue !== 'No') {
                                    const tagFriendlyName = tagFriendlyNames[tags.indexOf(item.KeyName)];
                                    const tagId = tagFriendlyName.replace(/\s+/g, '');
                                    return (
                                        <>
                                            <span className={`tag tag_${tagId}`}>{tagFriendlyName}</span>
                                        </>
                                    );
                                }
                            })}
                        </div>

                        <div className={'specifications_rows'}>
                            {// @ts-ignore
                            product.AttributeValues?.slice()?.map((item, i) => {
                                if (item.KeyName && specificationsList.includes(item.KeyName) && item.TextValue !== 'No') {
                                    const specificationFriendlyName = specificationsListFiendly[specificationsList.indexOf(item.KeyName)];
                                    const specificationId = specificationFriendlyName.replace(/\s+/g, '');
                                    return (
                                        <div className={specificationId}>
                                            <h4>{specificationFriendlyName}</h4>
                                            <div className={'content'}>{item.TextValue}</div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    );
};

export default Popup;
